<template>
  <div class="result">
    <div class="result_box">
      <img src="@/assets/images/res.png" alt="" />
      <div class="text_box" v-if="!retUrl">
        <p>提交成功，点击查看测评报告。</p>
        <div class="btn" @click="handelEvaluationReport()">测评报告</div>
      </div>
      <div class="text_box" v-else>
        <p>提交成功</p>
        <div class="btn" @click="handelConfirm()">确定</div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      retUrl: ''
    };
  },
  components: {},
  created() {
    this.retUrl = localStorage.getItem('retUrl');
  },
  methods: {
    handelEvaluationReport() {
      this.$router.replace({ path: "/report", query: { code: this.code, back: 1 } });
      // this.$router.push({ path: "/EvaluationReport", query: { code: this.code, back: 1 } });
    },
    handelConfirm() {
      window.location = localStorage.getItem('retUrl')
    }
  },
  props: {
    code: {
      type: String,
      default: () => {
        return ''
      }
    }
  }
};
</script>

<style scoped lang="less">
.result {
  .result_box {
    width: 5.4rem;

    img {
      width: 100%;
      height: 3.26rem;
    }

    .text_box {
      text-align: center;
      overflow: hidden;
      padding-bottom: 1rem;
      background-color: #ffffff;

      p {
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        line-height: 0.38rem;
        margin-top: 0.49rem;
      }

      .btn {
        width: 2rem;
        height: 0.7rem;
        background: #f2961c;
        border-radius: 0.35rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
        font-family: "PingFangSC-Regular";
        font-weight: 500;
        color: #ffffff;
        margin: 0.49rem auto 0;
      }
    }
  }
}


</style>
