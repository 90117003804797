<template>
  <div class="answer">
    <navigationBar :showBack="showBack" :back="back" :out="'out'">{{ name }}</navigationBar>
    <div class="line_box">
      <div class="num_box">
        <span>{{page &lt; 10 ? '0' + page : page}}</span
        >/{{ questionLength }}
      </div>
      <div class="right_box">
        <div class="line" :style="'width:' + lineWidth + '%'"></div>
      </div>
      <div v-if="timeLineShow > 0">
        <div class="remainder" v-if="timeShow" style="color: #F40000;"> <img src="https://file.jycyfww.com/wangchuang/qc/prod/static/img/d776b313817e4d1291db307c891468f2.png" alt=""> <span>{{time}}</span></div>
        <div class="remainder" v-else> <img src="https://file.jycyfww.com/wangchuang/qc/prod/static/img/d776b313817e4d1291db307c891468f2.png" alt=""> <span>{{time}}</span></div>
      </div>
    </div>
    <div class="answer_item">
      <div class="title">{{ questionData.content }}</div>
      <div class="option_box">
        <div
          v-for="(item, index) of questionData.options"
          :key="index"
          :class="{ active: item.selected }"
          @click="handelQuestionItem(index)"
        >
          <p v-if="item.content.indexOf('https://dyqc') == -1" style="display:inline;">{{ item.content }}</p>
          <img v-else style="height:42px;vertical-align: top;" :src="item.content" alt="">
        </div>
      </div>
    </div>
    <div class="cool_box">
      <div @click="handelPrevQuestion()" v-show="showPrev">上一题</div>
      <div @click="throttle()" v-show="showNext">下一题</div>
    </div>
    <div class="submit" v-show="showSub" @click="handelSubmit()">提交</div>
    <van-popup v-model="show" :close-on-click-overlay="clickFalg">
      <result :code="code"></result>
    </van-popup>
    <!-- 超时弹框 -->
    <van-popup v-model="timeOutShow" :close-on-click-overlay="clickFalg">
      <div class="popup_warp">
        <img class="close" @click="handleClose()" src="https://file.jycyfww.com/wangchuang/qc/prod/static/img/0de3b4b44e224563b85bfa7c93246a54.png" alt="">
        <div class="content">
          <div class="content_warp">
            <img src="https://file.jycyfww.com/wangchuang/qc/prod/static/img/d0ae4c9383794be99528e6d1b883a688.png" alt="">
            <div>测试超时，测评关闭</div>
          </div>
        </div>
        <div class="submit" @click="handleClose()">重新测试</div>
      </div>
    </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import { Toast } from "vant";
import navigationBar from "@/components/navigationBar/navigationBar";
import result from "@/components/result/result";
import {
  getQuestion,
  getQuestionHistory,
  getSubQuestionItem,
  getConfirmSub,
  getEvaluation,
  getBatchSubmit,
  getConfirmSubmit
} from "@/request/api";
export default {
  data() {
    return {
      name: "",
      lineWidth: 0,
      show: false,
      showBack: true,
      code: 0,
      subjectIds: [],
      questionLength: 0,
      page: 1,
      loginUser: {},
      userId: 0,
      questionData: {},
      questionId: 0,
      score: 0,
      isActiveIndex: 0,
      showPrev: false,
      showSub: false,
      showNext: true,
      isChoice: false,
      selectd: 0,
      clickFalg: false,
      lastTime: 0,
      back:  1,
      timeLine: 0,
      time: '',
      timeOutShow: false,
      timeLineShow: false,
      timeInterval: null,
      timeShow: false,
      batchAnswers: []
    };
  },
  created() {
    // code
    this.code = this.$route.query.evaluationId;
    // 标题
    this.name = JSON.parse(localStorage.getItem("name"));
    // 总题数
    this.subjectIds = JSON.parse(localStorage.getItem("subjectIds"));
    // 用户信息
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
    // 总题数长度
    this.questionLength = this.subjectIds.length;
    // 用户id
    this.userId = this.loginUser.id;
  },
  mounted() {
    this.getQuestionHistory(this.code, this.userId);
    this.getEvaluation(this.code);
  },
  methods: {
    // 获取答题历史
    getQuestionHistory(id, page) {
      let param = {
        code: id,
        id: page,
      };
      getQuestionHistory(param).then((res) => {
        this.page = res.data.length ? res.data.length + 1 : 1;
        this.page = this.page > this.questionLength ? this.questionLength : this.page
        this.showPrev = this.page == 1 ? false : true;
        this.showSub = this.page == this.questionLength ? true : false;
        this.showNext = this.page == this.questionLength ? false : true;
        this.getQuestion(this.code, this.page);
      });
    },

    // 获取题目
    getQuestion(code, page, type) {
      let param = {
        code: code,
        id: page,
      };
      getQuestion(param).then((res) => {
        this.questionData = res.data;
        this.questionId = res.data.subjectId;
        this.lineWidth = (100 / this.questionLength) * this.page;
        this.selectd = res.data.selectd;
        if (this.selectd != 0) {
          this.isChoice = true;
          let question = this.questionData.options;
          for (let item of question) {
            item.selected = false;
          }
          question[this.selectd - 1].selected = true;
          this.$forceUpdate();
        } else {
          this.isChoice = false;
        }
        if(this.timeLineShow > 0 && type) {
          let question = this.questionData.options;
          if(this.batchAnswers[this.page - 1]) {
            question.forEach((item, index) => {
              if(item.optionOrder == this.batchAnswers[this.page - 1].optionOrder) {
                question[index].selected = true;
                this.isChoice = true;
              }
            })
          }
        }
      });
    },

    // 点击选项
    handelQuestionItem(index) {
      this.isChoice = true;
      let question = this.questionData.options;
      for (let item of question) {
        item.selected = false;
      }
      question[index].selected = true;
      this.$forceUpdate();
      // 限时测评
      if(this.timeLineShow > 0) {
        const params = {
          subjectId: question[index].subjectId,
          optionOrder: question[index].optionOrder,
          score: question[index].score,
        }
        this.batchAnswers[this.page - 1] = params;
      }
    },

    // 下一题
    handelNextQuestion() {
      // 限时测评
      if(this.timeLineShow > 0) {
        if (this.isChoice) {
          this.page++;
          this.lineWidth = (100 / this.questionLength) * this.page;
          if (this.page >= this.questionLength) {
            this.showPrev = true;
            this.showSub = true;
            this.showNext = false;
          } else {
            this.showPrev = true;
            this.showSub = false;
            this.showNext = true;
          }
          this.getQuestion(this.code, this.page, 'next');
        } else {
          Toast("请选择答案!");
        }
      } else {
        // 正常答题
        if (this.isChoice) {
          this.page++;
          this.lineWidth = (100 / this.questionLength) * this.page;
          if (this.page >= this.questionLength) {
            this.showPrev = true;
            this.showSub = true;
            this.showNext = false;
          } else {
            this.showPrev = true;
            this.showSub = false;
            this.showNext = true;
          }
          this.processingActiveData();
        } else {
          Toast("请选择答案!");
        }
      }
    },

    throttle() {
      //获取当前时间的时间戳
      let now = new Date().valueOf();
      //第一次点击
      if (this.lastTime == 0) {
        this.handelNextQuestion();
        this.lastTime = now;
      } else {
        if (now - this.lastTime > 500) {
          //重置上一次点击时间，2000是我自己设置的2秒间隔，根据自己的需要更改
          this.lastTime = now;
          this.handelNextQuestion();
          //添加自己要调用的方法
        } else {
          Toast("请勿频繁操作!");
        }
      }
    },

    // 上一题
    handelPrevQuestion() {
      // 限时测评
      if(this.timeLineShow > 0) {
        this.page--;
        this.lineWidth = (100 / this.questionLength) * this.page;
        if (this.page == 1) {
          this.showPrev = false;
          this.showSub = false;
          this.showNext = true;
        } else {
          this.showPrev = true;
          this.showSub = false;
          this.showNext = true;
        }
        this.getQuestion(this.code, this.page, 'prev');
      } else {
        this.page--;
        this.lineWidth = (100 / this.questionLength) * this.page;
        if (this.page == 1) {
          this.showPrev = false;
          this.showSub = false;
          this.showNext = true;
        } else {
          this.showPrev = true;
          this.showSub = false;
          this.showNext = true;
        }
        this.getQuestion(this.code, this.page);
      }
      
    },

    // 处理当前选择的数据
    processingActiveData() {
      let question = this.questionData.options;
      let arr = [];
      for (let item of question) {
        if (item.selected) {
          arr.push(1);
          this.questionId = item.subjectId;
          this.score = item.score;
          this.isActiveIndex = item.optionOrder;
        } else {
          arr.push(2);
        }
      }
      if (arr.indexOf(1) == -1) {
        Toast("请选择答案!");
        this.isChoice = false;
      } else {
        this.getSubQuestionItem();
        this.isChoice = true;
      }
    },

    // 提交每道题
    getSubQuestionItem() {
      let param = {
        id: this.userId,
        code: this.questionId,
        evaluationCode: this.code,
        optionOrder: this.isActiveIndex,
        score: this.score,
      };
      getSubQuestionItem(param).then((res) => {
        console.log(res);
        if (this.page <= this.questionLength) {
          this.getQuestion(this.code, this.page);
        } else {
          this.page = this.questionLength;
          this.getConfirmSub(this.code, this.userId);
        }
      });
    },

    // 提交
    handelSubmit() {
      // 限时测评
      if(this.timeLineShow > 0) {
        console.log(this.batchAnswers)
        if (this.isChoice) {
          const params = {
            evaluationCode: this.code,
            optionParams: this.batchAnswers
          }
          getBatchSubmit(params).then((res) => {
            console.log(res)
            clearInterval(this.timeInterval)
            const param = {
              timeLine: this.timeLineShow - this.timeLine,
              id: this.userId,
              code: this.code
            }
            getConfirmSubmit(param).then((result) => {
              console.log(result)
              this.show = true;
            })
          })
        } else {
          Toast("请选择答案!");
        }
      } else {
        this.handelNextQuestion();
      }
      
    },

    // 确认完成
    getConfirmSub(code, id) {
      let param = {
        code: code,
        id: id,
      };
      getConfirmSub(param).then((res) => {
        console.log(res);
        this.show = true;
      });
    },
    // 测评详情
    getEvaluation(id) {
      getEvaluation(id).then((res) => {
        this.timeLine = res.data.timeLine;
        this.timeLineShow = res.data.timeLine;
        this.time = this.formatSeconds(this.timeLine);
        if(this.timeLineShow > 0) {
            this.remainder = this.timeLine * 0.1;
            this.timeInterval = setInterval(() => {
              this.time = this.formatSeconds(this.timeLine--);
              if(this.timeLine < this.remainder) {
                this.timeShow = true;
              } else {
                this.timeShow = false;
              }
              if(this.timeLine == -1) {
                this.timeOutShow = true;
                clearInterval(this.timeInterval)
              }
            }, 1000)
          }
      });
    },
    // 秒转分
    formatSeconds(value) {
      var secondTime = parseInt(value);// 秒
      var minuteTime = 0;// 分
      var hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
          //获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60);
          //获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60);
          //如果分钟大于60，将分钟转换成小时
          if(minuteTime > 60) {
              //获取小时，获取分钟除以60，得到整数小时
              hourTime = parseInt(minuteTime / 60);
              //获取小时后取佘的分，获取分钟除以60取佘的分
              minuteTime = parseInt(minuteTime % 60);
          }
      }
      var result = parseInt(secondTime) < 10 ? '0' + parseInt(secondTime) + "" : parseInt(secondTime)
      if(minuteTime > 0) {
          result = parseInt(minuteTime) < 10 ? '0' + parseInt(minuteTime) + ":" + result : parseInt(minuteTime) + ":" + result
      }
      if(hourTime > 0) {
          result = parseInt(hourTime) < 10 ? '0' + parseInt(hourTime) + ":" + result : parseInt(hourTime) + ":" + result
      }
      return result;
    },
    // 关闭弹框
    handleClose() {
      this.timeOutShow = false;
      this.$router.go(-2);
    }
  },
  beforeDestroy() {
    if(localStorage.getItem('retUrl')) {
       window.location = localStorage.getItem('retUrl')
    }
    clearInterval(this.timeInterval)
  },
  components: {
    navigationBar,
    result,
  },
};
</script>

<style scoped lang="less">
.answer {
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
  min-height: calc(100vh - 0.88rem);
  overflow: hidden;
  .line_box {
    display: flex;
    align-items: center;
    padding: 0 2rem 0 0.3rem;
    margin: 0.36rem 0 0.70rem 0;
    position: relative;
    .num_box {
      font-size: 0.28rem;
      font-family: "PingFangSC-Regular";
      font-weight: 600;
      color: #333333;
      line-height: 0.45rem;
      margin-right: 0.19rem;

      span {
        font-size: 0.46rem;
        color: #0067b0;
      }
    }

    .right_box {
      width: 100%;
      height: 0.17rem;
      background: #f2f2f2;
      border-radius: 0.09rem;

      .line {
        height: 0.17rem;
        background: #0067b0;
        border-radius: 9px;
      }
    }
  }

  .answer_item {
    padding: 0 0.3rem;
    margin-bottom: 1rem;
    .title {
      font-size: 0.34rem;
      font-family: "PingFangSC-Regular";
      font-weight: 600;
      color: #333333;
      line-height: 0.45rem;
      margin-bottom: 0.5rem;
    }

    .option_box {
      div {
        font-size: 0.3rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        line-height: 0.45rem;
        padding: 0.32rem 0.22rem;
        background: #f2f2f2;
        border-radius: 0.1rem;
        margin-bottom: 0.2rem;
        border: 0.02rem solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:last-child {
        margin-bottom: 0;
      }

      .active {
        color: #0067b0;
        background: #ecf5fc;
        border: 0.02rem solid #0067b0;
      }
    }
  }

  .cool_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.2rem;

    div {
      font-size: 0.28rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #0067b0;
      line-height: 0.45rem;
      margin: 0 0.8rem;
    }
  }

  .submit {
    width: 6.89rem;
    height: 0.9rem;
    background: #0067b0;
    border-radius: 0.5rem;
    text-align: center;
    line-height: 0.9rem;
    text-align: center;
    font-size: 0.34rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #ffffff;
    margin: 0 auto;
  }
}

/deep/ .van-popup {
  background-color: transparent;
  border-radius: 0.1rem;
  overflow: hidden;
}

.popup_warp {
  width: 5.7rem;
  height: 3.09rem;
  background: #FFFFFF;
  border-radius: 0.1rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;

  .close {
    width: 0.24rem;
    height: 0.24rem;
    display: block;
    position: absolute;
    right: 0.4rem;
    top: 0.3rem;
    cursor: pointer;
  }

  .content {
    

    .content_warp {
      display: flex;
      align-items: center;
      font-size: 0.28rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 0.86rem;

      img {
        width: 0.37rem;
        height: 0.37rem;
        display: block;
        margin-right: 0.25rem;
      }
    }
  }

  .submit {
    width: 2rem;
    height: 0.7rem;
    background: #1555E7;
    border-radius: 21px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.6rem;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.remainder {
  font-size: 0.3rem;
  font-family: DIN;
  font-weight: bold;
  color: #333333;
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 0.3rem;
    height: 0.3rem;
    display: block;
    margin-right: 0.12rem;
  }
}
</style>
